<template>
  <div>
    <div class="hr-sect subtitle-1 text--theme--primary" v-if="!show" @click="show = true" style="cursor: pointer;">
      {{dividerText}}
    </div>
    <v-expand-transition>
      <v-card outlined v-if="show" class="mt-1">
        <v-card-title>
          <span>{{cardTitle ? cardTitle : dividerText}}</span>
          <v-btn icon color="primary" @click="show = false" class="pl-2 ml-auto">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-html="cardHtml">
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
  export default {
    name: "DividerInfoButton",
    props: {
      dividerText: {
        type: String
      },
      cardTitle: {
        type: String
      },
      cardHtml: {
        type: String
      }
    },
    data() {
      return {
        show: false
      }
    }
  }
</script>

<style scoped>
  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin: 8px 0px;
  }
  .hr-sect::before,
  .hr-sect::after {
    content: "";
    flex-grow: 1;
    background: #00796B;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }
</style>