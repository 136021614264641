var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.show
        ? _c(
            "div",
            {
              staticClass: "hr-sect subtitle-1 text--theme--primary",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function($event) {
                  _vm.show = true
                }
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.dividerText) + "\n  ")]
          )
        : _vm._e(),
      _c(
        "v-expand-transition",
        [
          _vm.show
            ? _c(
                "v-card",
                { staticClass: "mt-1", attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.cardTitle ? _vm.cardTitle : _vm.dividerText
                          )
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pl-2 ml-auto",
                          attrs: { icon: "", color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.show = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", {
                    domProps: { innerHTML: _vm._s(_vm.cardHtml) }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }