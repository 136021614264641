<template>
  <v-row class="ma-2">
    <v-col :cols="12" :sm="7" v-if="$store.getters.isSubscribed">
      <v-alert type="error" v-if="$store.getters.isAccountPastDue">
        Your account is past due because of issues with your payment information. Please update your card info.
      </v-alert>
      <v-text-field
          class="mb-2"
          :readonly="true"
          v-model="$store.getters.getSubscriptionPlan"
          hint="* adjustments due to discounts and account credits are not shown"
          :persistent-hint="true"
          color="error"
          :label="`Subscription Plan${$store.state.account.account.accountSettings.status ? ` (${$store.state.account.account.accountSettings.status})` : '' }`"
      />
      <span v-if="$store.getters.isSubscriptionAutoRenewalDisabled">{{'Your subscription has auto-renewal disabled and will be canceled on ' + new Date($store.state.account.account.accountSettings.nextBill).toLocaleDateString() + '.' }}</span>
      <template v-else>
        <div v-if="$store.state.account.account.accountSettings.nextBill" class="caption">{{`Next bill: ${new Date($store.state.account.account.accountSettings.nextBill).toLocaleDateString()}`}}</div>

        <template v-if="$store.state.account.account.accountSettings.cardInfo">
          <div v-if="$store.state.account.account.accountSettings.cardInfo.brand" class="caption">{{`Card type: ${$store.state.account.account.accountSettings.cardInfo.brand}`}}</div>
          <div v-if="$store.state.account.account.accountSettings.cardInfo.last4" class="caption">{{`Card ending with: *${$store.state.account.account.accountSettings.cardInfo.last4}`}}</div>
          <div v-if="$store.state.account.account.accountSettings.cardInfo.postalCode" class="caption">{{`Zip/Postal Code: ${$store.state.account.account.accountSettings.cardInfo.postalCode}`}}</div>
        </template>

      </template>
      <div v-if="$store.state.account.account.accountSettings.subscriptionProcessor" class="caption mt-2">
        {{`Payment processor: ${$store.state.account.account.accountSettings.subscriptionProcessor}`}}
      </div>
    </v-col>

    <v-col v-if="showUseWebBrowserMessage" :cols="12" class="font-weight-bold">
      To upgrade or cancel your subscription, please use the Truple website.
    </v-col>
    <template v-else>
      <v-col :cols="12" :sm="5" class="pa-2" :offset-sm="$store.getters.isSubscribed ? 0 : 7">
        <v-btn v-if="!$store.getters.isSubscribed" @click="showSubscribeDialog()"
               block
               :loading="loading"
               :disabled="loading"
               color="primary"
               width="100%">Subscribe</v-btn>
        <template v-else>
          <v-btn
              @click="showChangeSubscriptionDialog()"
              outlined
              block
              color="primary">
            {{$store.getters.isSubscriptionAutoRenewalDisabled ? 'Restart Subscription' : 'Change Subscription'}}
          </v-btn>
          <span class="caption" v-if="$store.getters.getSubscriptionAllowedMonitoredDeviceCount >= 15">
            Your account has a <span>{{$store.getters.getSubscriptionAllowedMonitoredDeviceCount}}</span> device limit. To increase it, follow <a :href="`https://${supportDomain}/articles/account/increase-device-limit`">these instructions</a>.
          </span>

          <v-badge class="mt-4" :value="isAccountPastDue" style="width: 100%;" overlap left offset-x="125px" offset-y="10px" color="error">
            <span slot="badge">
              update card info!
            </span>
            <v-btn
                v-if="!$store.getters.isSubscriptionAutoRenewalDisabled"
                @click="changeCard()"
                outlined
                block
                class="mb-4"
                :disabled="changingCard"
                :loading="changingCard"
                color="primary">
              Change Card
            </v-btn>
          </v-badge>

        </template>
      </v-col>
      <v-col :cols="12" :sm="5" :offset-sm="7" class="pl-2" v-if="$store.getters.isSubscribed">
        <v-alert text dense type="warning" class="mt-2" v-if="!$store.getters.isCurrentSubscriptionPlanAvailable">
          Your current plan is grandfathered in.  If you cancel you won't be able to resubscribe at the same price.
        </v-alert>
        <v-btn @click.stop="openCancelDialog" outlined block color="secondary" v-if="!$store.getters.isSubscriptionAutoRenewalDisabled" :disabled="$store.getters.isAccountLocked">Cancel Subscription</v-btn>
        <p v-if="$store.getters.isAccountLocked">Your account is locked.  To cancel your subscription, you must first unlock your account.</p>
      </v-col>
    </template>
  </v-row>

</template>

<script>
import ChargeApi from "@/aws/charge/ChargeApi";

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

export default {
  name: "StripeSubscriptionManagement",
  components: {

  },
  data () {
    return {
      supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
      changingCard: false
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if(this.$route.query.changeCard) {
      this.changeCard()
    }
  },
  methods: {
    async checkIfPastDue() {
      await this.$store.state.accountLoadedPromise
      this.isAccountPastDue = this.$store.getters.isAccountPastDue
    },
    showSubscribeDialog() {
      this.$emit('start-subscribe')
    },
    showChangeSubscriptionDialog() {
      this.$emit('change-subscription')
    },
    openCancelDialog() {
      this.$emit('start-cancel')
    },
    async changeCard() {
      this.changingCard = true

      try {
        let response = await ChargeApi.createChangeCardSession(
            `${process.env.VUE_APP_HOST}/account`,
            `${process.env.VUE_APP_HOST}/account`,
        )

        await stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })
        this.changingCard = false
      } catch(e) {
        console.log(e)
        alert(`an error occurred (${e.toString()}) , please refresh the page and try again.  If this repeats, contact support@truple.io.`)
        if(e.error) {
          alert(`exact error message ${e.error.message}`)
        }
      } finally {
        this.changingCard = false
      }
    },
  },
  computed: {
    isAccountPastDue: {
      get: function() {
        return this.$store.getters.isAccountPastDue
      },
      set: function() {

      }
    },
    showUseWebBrowserMessage() {
      if(this.$store.state.inApp && this.$store.state.inAppInstalledFromGoogle) {
        return true
      } else {
        //already in a web browser
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>