<template>
    <v-container style="max-width: 800px;">
      <loading v-if="loading"></loading>
      <subscribe v-else-if="showSubscribe" @cancel="cancelSubscribeDialog" @done="subscribeDone" />
      <change-subscription v-else-if="showChangeSubscription" @cancel="cancelChangeSubscriptionDialog" @done="changeSubscriptionDialogDone" />
      <v-card v-else class="card basePadding">

        <v-card-title class="primary title white--text">
          <span>Membership & Billing</span>
          <v-spacer></v-spacer>
          <span>{{$store.state.account.accountId}}</span>
        </v-card-title>

        <stripe-subscription-management v-if="(!$store.getters.isSubscribed && !$store.state.inAppInstalledFromGoogle) || stripeSubscription" :loading="loadingSubscribe" @start-subscribe="showSubscribeDialog" @change-subscription="changeSubscription" @start-cancel="openCancelDialog" />
        <google-subscription-management v-else-if="(!$store.getters.isSubscribed) || googleSubscription" :loading="loadingSubscribe" @start-subscribe="showSubscribeDialog" @change-subscription="changeSubscription" @start-cancel="openCancelDialog" />
        <div v-else>
          If you can see this, contact support@truple.io and indicate that the subscription management component isn't working properly.
        </div>

        <template v-if="$store.getters.isAccountLocked !== null">
          <v-divider></v-divider>
          <account-lock class="ma-2"></account-lock>
        </template>

        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col :cols="12" :sm="5" :offset-sm="7" class="pa-2">
            <v-dialog v-model="showResetPasswordDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  v-on="on"
                  outlined
                  block
                  width="100%"
                >Reset Password</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  Reset Account Password
                </v-card-title>
                <v-card-text>
                  If you log in using your Google account, then Google controls your password and you must reset it with them.  See <a href="https://support.google.com/mail/answer/41078" target="_blank">this page</a> for instructions.
                  <br />
                  <br />
                  If you log in using an email/password, you can reset your password by logging out and clicking "<b>Forgot password?</b>" on the login page.
                </v-card-text>
                <v-card-actions>
                  <v-btn class="primary" @click="showResetPasswordDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <template v-if="$store.getters.isSubscribed">
          <v-divider></v-divider>
          <e2-e-encryption-management
            class="ma-2" />
        </template>
      </v-card>

      <v-dialog v-model="showSubscriptionCancelledDialog" max-width="400">
        <v-card>
          <v-card-title>
            Subscription canceled
          </v-card-title>
          <v-card-text>
            Your subscription's autorenewal has been cancelled.  You'll continue to have access to Truple for the remainder of your billing period.
          </v-card-text>
          <v-card-actions class="centered">
            <v-btn @click="showSubscriptionCancelledDialog = false" class="secondary">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showLeaveReviewDialog" max-width="400">
        <v-card>
          <v-card-title>
            Would you mind leaving a review?
          </v-card-title>
          <v-card-text>
            <div>Your subscription has been cancelled. Real quick though, <span class="font-weight-bold">would you mind leaving a review for Truple?</span>  We would really appreciate it.</div>
            <br />
            <a @click="leaveReview">Click here to leave a review.</a>
            <div class="caption font-italic">If you're <u>short on time</u> just leave a star rating without a written review.</div>
          </v-card-text>
          <v-card-actions class="centered">
            <v-btn @click="showLeaveReviewDialog = false" class="secondary">
              Close
            </v-btn>
            <v-btn @click="leaveReview()" class="primary">
              Leave Review
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <email-recipient-invite-dialog @on-accept="accountabilityPartnerAccepted"></email-recipient-invite-dialog>

      <v-dialog v-model="showCancelDialog" :fullscreen="$vuetify.breakpoint.smAndDown" max-width="960px" class="d-flex">
        <cancelation-survey @on-complete="cancelSubscription" v-if="showCancelDialog" @cancel="cancelCancelation" class="mx-auto my-auto" />
      </v-dialog>

      <v-dialog v-model="showDohWarningDialog" max-width="500px" :fullscreen="$store.state.isMobile">
        <v-card>
          <v-card-title>
            <v-icon class="error--text pr-1">warning</v-icon>
            iOS Warning
          </v-card-title>
          <v-card-text>
            <div class="headline pb-1">Please remove Truple from your iPhone/iPads.  <span class="error--text">If you do not remove Truple before unsubscribing, you'll lose internet connection on all iOS devices!</span></div>
            <a href="https://blog.truple.io/2020/09/21/remove-doh-from-ios.html">View instructions for removing Truple from iOS devices.</a>
            <div class="headline pt-5">Have you removed Truple from all of your iPhone/iPad devices?</div>
          </v-card-text>
          <v-card-actions>
            <v-btn @click.stop="showDohWarningDialog = false">No</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click.stop="closeIOSCancelWarningDialog">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<!--TODO: // refactor to remove all the duplicate code...  replace credit card form, plan choice, plan confirmation (promocode) with custom components-->

<script>
  /* eslint-disable no-console */
  import Loading from "../Loading";
  import StoreConstants from '../../StoreConstants'
  import AccountLock from './AccountLock'
  import EmailRecipientInviteDialog from "../EmailRecipientInviteDialog";
  import E2EEncryptionManagement from "./E2EEncryption/E2EEncryptionManagement";
  import AndroidInterface from "../../services/AndroidInterface";
  import SegmentTracking from "../../services/SegmentTracking";
  import Subscribe from "@/components/accounts/revenuecat/Subscribe";
  import ChargeApi from "@/aws/charge/ChargeApi";
  import ChangeSubscription from "@/components/accounts/revenuecat/ChangeSubscription";
  import CancelationSurvey from "@/components/accounts/CancelationSurvey/CancelationSurvey";
  import StripeSubscriptionManagement from "@/components/accounts/stripe/StripeSubscriptionManagement";
  import GoogleSubscriptionManagement from "@/components/accounts/google/GoogleSubscriptionManagement";
  import Utils from "@/Utils";

  export default {
    name: 'account',
    components: {
      GoogleSubscriptionManagement,
      StripeSubscriptionManagement,
      CancelationSurvey,
      ChangeSubscription,
      Subscribe,
      E2EEncryptionManagement,
      Loading,
      AccountLock,
      EmailRecipientInviteDialog
    },
    created() {
      this.selectedPlan = {};
    },
    data () {
      return {
        canUseGooglePlayForPurchases: AndroidInterface.canUseGooglePlayForPurchases(),
        changingCard: false,
        showResetPasswordDialog: false,
        loading: true,
        loadingSubscribe: false,
        selectedPlan: null,
        account: null,
        showCancelDialog: false,
        showDohWarningDialog: false,
        showModifyBilling: false,
        showModifyPlan: false,
        showSubscribe: false,
        showChangeSubscription: false,
        showLeaveReviewDialog: false,
        showSubscriptionCancelledDialog: false
      }
    },
    methods: {
      closeIOSCancelWarningDialog() {
        if(this.googleSubscription) {
          if(this.$store.state.inApp) {
            AndroidInterface.openWebsiteInBrowser('https://play.google.com/store/account/subscriptions')
          } else {
            window.open('https://play.google.com/store/account/subscriptions', '_blank')
          }
        } else {
          this.showCancelDialog = !(this.showDohWarningDialog = false)
        }
      },
      cancelChangeSubscriptionDialog() {
        this.showChangeSubscription = false
      },
      changeSubscriptionDialogDone() {
        this.showChangeSubscription = false
        this.loadAccount(true)
      },
      changeSubscription() {
        this.showChangeSubscription = true
      },
      accountabilityPartnerAccepted() {
        this.$router.push('home')
      },
      openCancelDialog() {

        let hasIOSBeenRemoved = false

        let devices = this.$store.state.devices
        if(devices && devices.length > 0) {
          devices = devices.filter(x => { return x.userAgent === 'doh' })
          if(devices.length > 0) {
            this.showDohWarningDialog = true
          } else {
            hasIOSBeenRemoved = true
          }
        } else {
          hasIOSBeenRemoved = true
        }

        if(hasIOSBeenRemoved) {
          if(this.googleSubscription) {
            if(this.$store.state.inApp) {
              AndroidInterface.openWebsiteInBrowser('https://play.google.com/store/account/subscriptions')
            } else {
              window.open('https://play.google.com/store/account/subscriptions', '_blank')
            }
          } else {
            this.showCancelDialog = true
          }
        }
      },
      cancelCancelation() {
        this.showCancelDialog = false
      },
      leaveReview() {
        this.showLeaveReviewDialog = false
        window.open('https://play.google.com/store/apps/details?id=com.camhart.netcountable', '_blank')
      },
      async showSubscribeDialog() {
        this.loadingSubscribe = true

        //we've had customers create duplicate subscriptions by signing up a second time by using a different browser window
        //this does a fresh load and then only shows the dialog if they aren't subscribed.  We also added a check to the backend
        // that errors out.
        await this.$store.dispatch(StoreConstants.actions.loadAccount)
        await this.$store.state.accountLoadedPromise

        if(!this.$store.getters.isSubscribed) {
          this.showSubscribe = true
        }
        setTimeout(() => {
          this.loadingSubscribe = false
        }, 2000)
      },
      subscribeDone() {
        this.showSubscribe = false
        this.loadAccount(true)
      },
      cancelModifyPlan() {
        this.showModifyPlan = false
        //TODO:  Tracking service
      },
      modifyPlanDone() {
        this.showModifyPlan = false
        this.loadAccount(true)
        //TODO:  Tracking service
      },
      cancelSubscribeDialog() {
        this.showSubscribe = false
      },
      async cancelSubscription(reasons) {
        this.loading = true

        console.log('cancelSubscription reasons')
        console.log(reasons)

        try {
          const timestamp = Date.now()
          let response = await ChargeApi.cancelStripeSubscription(reasons)
          console.log('response')
          console.log(response)


          for(let c = 0; c < 1000; c++) {
            await Utils.sleep(1000)
            await this.loadAccount()
            if(this.$store.getters.getSubscriptionLastModified > timestamp) {
              break;
            }
          }

          if(reasons[0].answer === 'extremely-helpful') {
            //ask to leave a review
            this.showLeaveReviewDialog = true
          } else {
            this.showSubscriptionCancelledDialog = true
          }
        } catch(error) {
          alert('error cancelling subscription, please try again.  If this repeats, contact support@truple.io')
        } finally {
          this.showCancelDialog = false
        }
      },
      async loadAccount() {
        try {
          this.loading = true
          await this.$store.dispatch(StoreConstants.actions.loadAccount) // TODO:  simplify all this, have Account reply on the store model
          this.loading = false
        } catch (error) {
          console.log(error)
        }
      },
      async sendToSubscribeIfNecessary() {
        if(this.$route.query.subscribe === "true" || this.$route.query.subscribe === true) {
          this.showSubscribeDialog()
          this.$route.query.subscribe = false
          return
        }

        await this.$store.state.accountLoadedPromise
        if(!this.$store.getters.isSubscribed) {
          this.showSubscribeDialog()
          this.$route.query.subscribe = false
        }
      }
    },
    computed: {
      stripeSubscription() {
        if(this.$store.state.account &&
            this.$store.state.account.account &&
            this.$store.state.account.account.accountSettings &&
            this.$store.state.account.account.accountSettings.subscriptionProcessor === 'stripe') {
          return true
        }
        return false
      },
      googleSubscription() {
        if(this.$store.state.account &&
            this.$store.state.account.account &&
            this.$store.state.account.account.accountSettings &&
            this.$store.state.account.account.accountSettings.subscriptionProcessor === 'google') {
          return true
        }
        return false
      }
    },
    mounted() {
      SegmentTracking.page('Account')

      if(AndroidInterface.canUseGooglePlayForPurchases()) {
        AndroidInterface.checkForFailedGooglePlayPurchases()
      }
    },
    beforeMount() {
      if(this.$store.state.inApp && !AndroidInterface.canUseGooglePlayForPurchases()) {
        AndroidInterface.openAppPageInBrowser('account')
        this.$router.back()
      } else {
        this.loadAccount()
        this.sendToSubscribeIfNecessary()
      }
    }
  }
</script>

<style>

</style>