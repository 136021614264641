var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.e2eeRegistered === true
        ? [
            _c("v-col", { attrs: { cols: 12, sm: 7 } }, [
              _vm._v(
                "\n        End-to-end encryption (E2EE) is enabled on your account.  Please share your E2EE passcode with your report recipients.\n      "
              )
            ]),
            _c(
              "v-col",
              {
                staticClass: "pl-2 pr-0 pt-0 pb-0",
                attrs: { cols: 12, sm: 5 }
              },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "800px",
                      fullscreen: _vm.$store.state.isMobile
                    },
                    on: { close: _vm.cleanupPasscodeDialog },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      color: "primary",
                                      outlined: "",
                                      block: "",
                                      width: "100%"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("Change E2EE Passcode")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1198429246
                    ),
                    model: {
                      value: _vm.showE2EEPasscodeChangeDialog,
                      callback: function($$v) {
                        _vm.showE2EEPasscodeChangeDialog = $$v
                      },
                      expression: "showE2EEPasscodeChangeDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass: "d-inline-block",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Change End-to-End Encryption (E2EE) Recovery Passcode"
                              )
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.dialogLoading,
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeChangeDialog = false
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c("v-alert", { attrs: { type: "error" } }, [
                              _vm._v("\n                This will "),
                              _c("b", [_vm._v("NOT")]),
                              _vm._v(" change your "),
                              _c("b", [_vm._v("account")]),
                              _vm._v(" password.\n              ")
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n                You can change your E2EE Recovery passcode at anytime.\n                You and your report recipients will not need to enter the new passcode unless a new device/browser is used.\n                Once changed, the old passcode will no longer be valid.\n              "
                              )
                            ]),
                            _c("div", { staticClass: "font-italic caption" }, [
                              _vm._v("Changing the E2EE passcode does "),
                              _c("b", [_vm._v("NOT")]),
                              _vm._v(" generate a new certificate key pair.")
                            ]),
                            _c(
                              "form",
                              { attrs: { id: "changeE2eePasscodeForm" } },
                              [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "text",
                                    name: "usr",
                                    readonly: true,
                                    autocomplete: "username"
                                  },
                                  domProps: {
                                    value: _vm.decryptionPasscodeUsername
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    "persistent-hint": true,
                                    type: "password",
                                    placeholder: "Enter your current passcode",
                                    autocomplete: "current-password",
                                    hint:
                                      "Current Passcode.  If you've forgotten your current passcode, then you must reset your passcode."
                                  },
                                  model: {
                                    value: _vm.originalE2eePasscode,
                                    callback: function($$v) {
                                      _vm.originalE2eePasscode = $$v
                                    },
                                    expression: "originalE2eePasscode"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Enter your new passcode",
                                    autocomplete: "new-password",
                                    hint: "New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscode,
                                    callback: function($$v) {
                                      _vm.e2eePasscode = $$v
                                    },
                                    expression: "e2eePasscode"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Confirm your new passcode",
                                    autocomplete: "new-password",
                                    hint: "Confirm New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscodeConfirm,
                                    callback: function($$v) {
                                      _vm.e2eePasscodeConfirm = $$v
                                    },
                                    expression: "e2eePasscodeConfirm"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("passcode-requirements"),
                            _vm.errorMessage
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { type: "error", dense: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.errorMessage) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: !_vm.$store.state.isMobile,
                                  color: "secondary",
                                  disabled: _vm.dialogLoading,
                                  icon: _vm.$store.state.isMobile
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeChangeDialog = false
                                  }
                                }
                              },
                              [
                                _c("v-icon", [_vm._v("close")]),
                                !_vm.$store.state.isMobile
                                  ? _c("span", [_vm._v("Close")])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  disabled: _vm.dialogLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeChangeDialog = !(_vm.showE2EEPasscodeResetDialog = true)
                                  }
                                }
                              },
                              [_vm._v("forgot passcode")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  disabled:
                                    !_vm.passCodeValid || _vm.dialogLoading,
                                  loading: _vm.dialogLoading
                                },
                                on: { click: _vm.changePasscode }
                              },
                              [_vm._v("Submit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "800px",
                      fullscreen: _vm.$store.state.isMobile
                    },
                    on: { close: _vm.cleanupPasscodeDialog },
                    model: {
                      value: _vm.showE2EEPasscodeResetDialog,
                      callback: function($$v) {
                        _vm.showE2EEPasscodeResetDialog = $$v
                      },
                      expression: "showE2EEPasscodeResetDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _vm._v(
                            "\n              Rotate E2EE Passcode\n            "
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c("div", [
                              _vm._v(
                                "\n                You can rotate the E2EE passcode if you have forgotten your current passcode.\n              "
                              )
                            ]),
                            _c("div", { staticClass: "font-italic caption" }, [
                              _vm._v("Resetting the E2EE passcode "),
                              _c("b", [_vm._v("does")]),
                              _vm._v(" generate a new certificate key pair.")
                            ]),
                            _c("passcode-requirements"),
                            _c(
                              "form",
                              { attrs: { id: "resetDecryptionPasscodeForm" } },
                              [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "text",
                                    name: "usr",
                                    readonly: true,
                                    autocomplete: "username"
                                  },
                                  domProps: {
                                    value: _vm.decryptionPasscodeUsername
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Enter your new passcode",
                                    autocomplete: "new-password",
                                    hint: "New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscode,
                                    callback: function($$v) {
                                      _vm.e2eePasscode = $$v
                                    },
                                    expression: "e2eePasscode"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Confirm your new passcode",
                                    autocomplete: "new-password",
                                    hint: "Confirm New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscodeConfirm,
                                    callback: function($$v) {
                                      _vm.e2eePasscodeConfirm = $$v
                                    },
                                    expression: "e2eePasscodeConfirm"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-alert", { attrs: { type: "warning" } }, [
                              _vm._v(
                                "\n                Rotating the passcode will cause you and your report recipients to lose access to all existing screenshots, websites, & event data.  Only data encrypted after you change the passcode will be accessible.\n              "
                              )
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  block: "",
                                  disabled:
                                    !_vm.passCodeValid || _vm.dialogLoading,
                                  loading: _vm.dialogLoading
                                },
                                on: { click: _vm.rotatePasscode }
                              },
                              [
                                _vm._v(
                                  "\n                Rotate Passcode\n              "
                                )
                              ]
                            ),
                            _vm.errorMessage
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { type: "error", dense: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.errorMessage) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: "",
                                  disabled: _vm.dialogLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeResetDialog = false
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm.e2eeRegistered === false
        ? [
            _c(
              "v-col",
              {
                staticClass: "pt-1 pb-4 pl-2 pr-2",
                attrs: { cols: 12, sm: 5, "offset-sm": 7 }
              },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "800px",
                      fullscreen: _vm.$store.state.isMobile
                    },
                    on: { close: _vm.cleanupPasscodeDialog },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    color: "warning",
                                    outlined: "",
                                    block: "",
                                    width: "100%"
                                  }
                                },
                                on
                              ),
                              [_vm._v("Enable E2E Encryption")]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.showE2EEDialog,
                      callback: function($$v) {
                        _vm.showE2EEDialog = $$v
                      },
                      expression: "showE2EEDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _vm._v(
                            "\n              End-to-End Encryption (E2EE)\n            "
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _vm._v(
                              "\n\n              E2EE Frequently Asked Questions:\n\n              "
                            ),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "What does end-to-end encryption mean?",
                                "card-html":
                                  "Pretend you want to open a safety deposit box at a bank.  The bank offers two types of safety deposit boxes.<ul><li>One box requires a single key to open.  The key is held by the bank, stored in a safe, and only pulled out when requested by a bank customer to open their box.</li><li>The second type of safety deposit box requires two keys.  It requires the same bank key, but it now also requires an additional key that stays with you.</li></ul>These two different styles of safety deposit boxes describe, in a simplified way, the difference between common encryption and end-to-end encryption.  Truple's End-to-End Encryption (E2EE) requires two keys to decrypt the data.  One key is held by Truple and another by you and your report recipients.  This second key means that even if Truple were to get hacked and the hacker stole your data files from Truple's database <b>and</b> stole Truple's key, the hacker would also need to obtain your key (recovery passcode or private key) before they could decrypt the end-to-end encrypted data."
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "What platforms offer End-to-End Encryption?",
                                "card-html":
                                  "              End-to-End Encryption is supported on the following platforms:\n              <ul>\n                <li>Android</li>\n                <li>Windows</li>\n                <li>Mac</li>\n                <li>Linux</li>\n                <li>Chromebook</li>\n                <li>iOS - Not possible for now due to issues with Apple</li>\n              </ul>"
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "What benefits are there to using E2EE?",
                                "card-html":
                                  "End-to-end encryption puts you in control of your data and prevents others from being able to read your data unless they obtain your E2EE recovery passcode or an unencrypted form of the private key that stays on you and your report recipient's devices.  Your passcode and unencrypted private key are never sent to Truple's servers."
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "What drawbacks are there to using E2EE?",
                                "card-html":
                                  "You will have to share your E2EE recovery passcode with your report recipients, enabling them to decrypt the data.  You and your report recipient's will have to provide this passcode at least one time on each device / browser you use to decrypt the data."
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "What data is encrypted end-to-end?",
                                "card-html":
                                  "Screenshots and data describing websites visited and apps used are encrypted end-to-end.  Timestamps are only encrypted using common encryption to enable us to proviate accurate time based queries and to scale our services.  Tamper alerts and queries sent to Truple's contextual data rating service are only encrypted with common encryption, however this data isn't persisted beyond temporary, short lived log files on Truple's servers.  We will continue to look for ways to provide E2EE for this data as well."
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "How does Truple's E2EE recovery passcode work?",
                                "card-html":
                                  "A strong key is derived from your passcode using elliptic curve BLS12-381.  This derived key is then used to encrypt your account's private key before backing it up online.  This allows you to access your encrypted data from new devices that don't have your private key by simply by entering your E2EE recovery passcode."
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "I thought Truple already encrypted my data?",
                                "card-html":
                                  "We do! End-to-end encryption is different from normal encryption.  End-to-end encryption adds an additional layer of protection that you control around your data."
                              }
                            }),
                            _c("divider-info-button", {
                              attrs: {
                                "divider-text":
                                  "Will this prevent law enforcement from viewing my data?",
                                "card-html":
                                  "Truple's end-to-end encryption is not designed to thwart law enforcement.  However, law enforcement will not be able to view your end-to-end encrypted data without first obtaining your private key or e2ee recovery passcode.  Law enforcement could gain access to your passcode through you or any of your report recipients.  Law enforcement could obtain your private key by accessing you or your report recipient's device which contains the private key on it."
                              }
                            }),
                            _c("br"),
                            _c("div", { staticClass: "headline" }, [
                              _vm._v("E2EE Recovery Passcode")
                            ]),
                            _vm._v(
                              "\n\n              We strongly recommend you:\n              "
                            ),
                            _c("ul", [
                              _c("li", [
                                _vm._v("Write down your passcode on paper")
                              ]),
                              _c("li", [
                                _vm._v("Let your browser save your passcode")
                              ]),
                              _c("li", [
                                _vm._v(
                                  "Only share the passcode with those you want to be able to view your reports in a safe & secure manner (in person is great if possible)."
                                )
                              ])
                            ]),
                            _c("br"),
                            _c(
                              "v-expand-transition",
                              [
                                !_vm.showEnableE2EEForm
                                  ? _c(
                                      "v-card",
                                      { attrs: { outlined: "" } },
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-alert",
                                              { attrs: { type: "warning" } },
                                              [
                                                _vm._v(
                                                  "\n                      You "
                                                ),
                                                _c("b", [_vm._v("MUST")]),
                                                _vm._v(
                                                  " share your E2EE pass code with everyone you send your accountability reports to.\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.showEnableE2EEForm = true
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      I understand\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "form",
                                      { attrs: { id: "enableE2eeForm" } },
                                      [
                                        _c("input", {
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            type: "text",
                                            name: "usr",
                                            readonly: "true",
                                            autocomplete: "username"
                                          },
                                          domProps: {
                                            value:
                                              _vm.decryptionPasscodeUsername
                                          }
                                        }),
                                        _c("v-text-field", {
                                          attrs: {
                                            autofocus: true,
                                            type: "password",
                                            placeholder: "Recovery passcode",
                                            "persistent-hint": true,
                                            autocomplete: "new-password",
                                            hint: "Passcode"
                                          },
                                          model: {
                                            value: _vm.e2eePasscode,
                                            callback: function($$v) {
                                              _vm.e2eePasscode = $$v
                                            },
                                            expression: "e2eePasscode"
                                          }
                                        }),
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "password",
                                            placeholder:
                                              "Confirm recovery passcode",
                                            "persistent-hint": true,
                                            autocomplete: "new-password",
                                            hint: "Confirm Passcode"
                                          },
                                          model: {
                                            value: _vm.e2eePasscodeConfirm,
                                            callback: function($$v) {
                                              _vm.e2eePasscodeConfirm = $$v
                                            },
                                            expression: "e2eePasscodeConfirm"
                                          }
                                        }),
                                        _c("passcode-requirements"),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              color: "primary",
                                              block: "",
                                              disabled:
                                                !_vm.passCodeValid ||
                                                _vm.dialogLoading,
                                              loading: _vm.dialogLoading
                                            },
                                            on: { click: _vm.enableE2EE }
                                          },
                                          [_vm._v("Enable E2E Encryption")]
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: "",
                                  color: "secondary",
                                  disabled: _vm.dialogLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEDialog = false
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "v-col",
              {
                staticClass: "pa-2 text-center",
                attrs: { cols: 12, sm: 5, "offset-sm": 7 }
              },
              [
                _c("v-progress-circular", {
                  attrs: { size: 25, indeterminate: "", color: "primary" }
                })
              ],
              1
            )
          ],
      _vm.$store.state.account &&
      _vm.$store.state.account.account.debug &&
      _vm.e2eeRegistered === true
        ? _c(
            "v-col",
            { staticClass: "pa-2", attrs: { cols: 12, sm: 5, "offset-sm": 7 } },
            [
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.dialogLoading },
                  on: { click: _vm.unregisterUserFromE2ee }
                },
                [_vm._v("Unregister User")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showNextSteps,
            callback: function($$v) {
              _vm.showNextSteps = $$v
            },
            expression: "showNextSteps"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "\n          " + _vm._s(_vm.nextStepsTitle) + "\n        "
                )
              ]),
              _c("v-card-text", {
                domProps: { innerHTML: _vm._s(_vm.nextStepsHtml) }
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", outlined: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showNextSteps = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.nextStepsHtmlButtonText || "Okay") +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }